import React from 'react';

import Layout from '../../components/Layout';
import UFHero from '../../components/UF-Hero/uf-hero';
import UFCards from '../../components/UF-Cards/uf-cards';
import FocusOn from '../../components/FocusOn/focus-on';
import UfOffer from '../../components/UF-Offer/uf-offer';
import UfHomeBanner from '../../components/uf-home/uf-home-banner';

const Home = () => {
  return (
    <Layout section="UF" page="/uterine-fibroids/">
      <>
        <UfHomeBanner/>

        <UFHero />

        <UFCards />

        <FocusOn />

        <UfOffer />
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/uterine-fibroids/';
  const title =
    'Uterine Fibroid Medication | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const pageTitle =
    'Uterine Fibroid Medication | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const description =
    'Learn about MYFEMBREE®, an FDA-approved once-daily pill proven to reduce heavy menstrual bleeding from uterine fibroids in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
