import React from 'react';
import { Link } from 'gatsby';

import UfHomeBannerIcon from '../../assets/images/uf-home/uf-home-banner-img.png';
export function UfHomeBanner() {
  return (
    <section className="uf-home-banner-container bg-purple-100">
      <div className="container mx-auto">
        <div className="uf-home-banner-container-content flex flex-col md:flex-row items-center justify-center">
          <div className="flex flex-row items-center mb-3 md:mb-0">
            <img
            className='w-100 img-fluid'
              src={UfHomeBannerIcon}

              alt=""
              loading="lazy"
            />
            <h3 className="font-mulish text-white-100 font-black">
              July is Fibroid <br /> Awareness Month.
            </h3>
          </div>
          <div className="divider"></div>
          <p className='font-mulish text-white-100 md:text-start text-center'>
            Millions of women live with heavy period bleeding from uterine
            fibroids. It’s time to discover relief.
          </p>
        </div>
      </div>
    </section>
  );
}

export default UfHomeBanner;
